<template>
  <Teleport to="body">
    <div
      class="fixed w-[450px] gap-3 z-[999] flex flex-col flex-grow-0 max-w-full bottom-20 left-1/2 -translate-x-1/2"
    >
      <ToastItem
        @close="remove(item)"
        v-for="item in toastItems"
        :key="item.id"
        :options="item"
      />
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { emitter } from "~/libs/emitter";
import { ToastItem, type ToastOptions } from ".";

type IToast = ToastOptions & { id: string };

const toastItems: Ref<IToast[]> = ref([]);

emitter.on("respostaToast", (data: any) => {
  const opt = data as ToastOptions;

  const id = Math.random().toString(36).substr(2, 9);
  toastItems.value.push({ ...opt, id });
});

const remove = (item: IToast) => {
  toastItems.value = toastItems.value.filter((i) => i.id !== item.id);
};
</script>
